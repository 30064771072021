import { useTheme } from '@mui/material/styles';

import logoDark from 'assets/images/logo-dark.png';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" height="75" />;
};

export default LogoMain;
