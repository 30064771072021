import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { useMemo } from 'react';
import MegaMenuSection from './MegaMenuSection';
import MobileSection from './MobileSection';
import Navigation from './Navigation';
import Notification from './Notification';
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ fullWidth }: { fullWidth: boolean }) => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const megaMenu = useMemo(() => <MegaMenuSection />, []);
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Stack direction="row" justifyContent="flex-start">
        {fullWidth && <Navigation />}
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
        {fullWidth && megaMenu}
        <Notification />
        {!matchesXs && <Profile />}
        {matchesXs && <MobileSection />}
      </Stack>
    </Grid>
  );
};

export default HeaderContent;
