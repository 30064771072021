import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Stack, Typography } from '@mui/material';
import { useSignOut } from 'api/auth';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Link as RouterLink } from 'react-router-dom';

// minutes
const LONGER = Number(process.env.REACT_APP_TIMEOUT || 30) * 60 * 1000;

const SHORTEST = 30 * 1000;

const Footer = () => {
  const { mutate } = useSignOut();
  const throttle = 1000;

  const [remaining, setRemaining] = useState<number>(LONGER);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setOpen(false);
    mutate(
      {},
      {
        onSuccess: () => {
          localStorage.clear();
          window.location.reload();
        }
      }
    );
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle: SHORTEST,
    crossTab: true,
    timeout: LONGER,
    throttle: throttle,
    syncTimers: throttle
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
      <Dialog open={open}>
        <DialogTitle variant="subtitle2" sx={{ m: '0px auto', alignSelf: 'center' }}></DialogTitle>
        <DialogContent>
          <Typography>Are you still here? You'll be logged out in {remaining} second(s)</Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, pt: 2 }}>
            <Button color="error" size="small" onClick={handleStillHere} variant="outlined">
              I'm here
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Typography variant="caption">&copy; All rights reserved</Typography>
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          About us
        </Link>
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          Privacy
        </Link>
        <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
          Terms
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
