import { useTheme } from '@mui/material/styles';
import logoIconDark from 'assets/images/logo-icon-dark.png';
import logoIcon from 'assets/images/logo-icon.png';

// ==============================|| LOGO ICON ||============================== //

const LogoIcon = () => {
  const theme = useTheme();
  return <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" height="75" />;
};

export default LogoIcon;
