import { Box, Container, Theme, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { getClient } from 'api/clients';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import MainCard from 'components/MainCard';
import { AuthContext } from 'contexts/auth';
import useConfig from 'hooks/useConfig';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { NavItemType } from 'types/menu';
import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';
import { getMenuItems } from './menu-items';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { user, active, company_id, default_role, menu: menuPref } = useContext(AuthContext);

  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { container, miniDrawer } = useConfig();

  const [navigation, setNavigation] = useState<{ items: NavItemType[] }>({ items: [] });
  const clientId = useMemo(() => (active ? company_id : ''), [active, company_id]);
  const { data: client } = useQuery(getClient(clientId));

  const menu = useSelector((state: any) => state.menu);
  const { drawerOpen } = menu;

  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (matchDownLG) {
      if (open !== drawerOpen) setOpen(drawerOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    const menuItems = getMenuItems(user, active, default_role, company_id);

    if (menuItems) {
      setNavigation(menuItems);
    }
  }, [user, active, default_role, company_id]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {client && <Header fullWidth={!matchesXs && menuPref === 'top'} open={open} handleDrawerToggle={handleDrawerToggle} />}
      {client && (matchesXs || menuPref === 'side') && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        {matchesXs && <Toolbar />}
        {container && (
          <Container
            maxWidth="lg"
            sx={{ px: { xs: 0, sm: 2 }, position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}
          >
            <MainCard>
              <Breadcrumbs navigation={navigation} titleBottom card={false} divider={false} />
              <Outlet />
              <Footer />
            </MainCard>
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <MainCard sx={{ position: 'relative', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
              <Breadcrumbs navigation={navigation} titleBottom card={false} divider={false} />
              <Outlet />
            </MainCard>
            <Footer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
