import { LinearProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'api/auth';
import Snackbar from 'components/@extended/Snackbar';
import ErrorFallback from 'components/ErrorFallback';
import { LoaderWrapper } from 'components/Loader';
import Locales from 'components/Locales';
import NetworkStatusIndicator from 'components/NetworkIndicator';
import { AuthContext } from 'contexts/auth';
import useConfig from 'hooks/useConfig';
import useNavigatorOnLine from 'hooks/useNavigatorOnline';
import { Settings } from 'luxon';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';
import ThemeCustomization from 'themes';

Settings.throwOnInvalid = true;
Settings.defaultZone = 'Africa/Nairobi';
Settings.defaultLocale = 'en-KE';

const App = () => {
  const { data: currentUser, isLoading } = useQuery(getCurrentUser());
  const defaults = { user: null, active: false, company_id: '', default_role: '', menu: 'top', roles: [] };
  const { user, active, company_id, default_role, menu, roles } = currentUser || defaults;

  const { onChangeNetwork } = useConfig();

  const { isOnline } = useNavigatorOnLine();

  useEffect(() => {
    onChangeNetwork(isOnline);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LinearProgress />
      </LoaderWrapper>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback error="" />} showDialog>
      <ThemeCustomization>
        <NetworkStatusIndicator />
        <Locales>
          <AuthContext.Provider value={{ user, active, company_id, default_role, menu, roles }}>
            <RouterProvider router={router} />
            <Snackbar />
            <Snackbar />
          </AuthContext.Provider>
        </Locales>
      </ThemeCustomization>
    </Sentry.ErrorBoundary>
  );
};

export default App;
