import { queryOptions, useMutation } from '@tanstack/react-query';
import { queryClient } from 'api';
import { AxiosRequestHeaders } from 'axios';
import saveAs from 'file-saver';
import { isEmpty } from 'lodash';
import axios, { base_url } from 'utils/axios';
import { formatResponse } from './helpers';

export const list = (url: string, params?: any, enabled = true) => {
  const cacheKey = localStorage.getItem('cacheTracker');
  const { filters, ...rest } = params || {};
  params = { ...filters, ...rest };

  return queryOptions({
    enabled: enabled,
    queryKey: [url, params, cacheKey],
    queryFn: async () => {
      const response = await axios.get(url, { params });
      return formatResponse(response.data);
    }
  });
};

export const retrieve = (url: string, enabled = true) => {
  const cacheKey = localStorage.getItem('cacheTracker');
  return queryOptions({
    enabled: enabled,
    queryKey: [url, cacheKey],
    queryFn: async () => {
      const response = await axios.get(url);
      return response.data;
    }
  });
};

export const useDelete = (params: { url: string }) => {
  const { url } = params;

  return useMutation({
    mutationFn: (data: any) => {
      const { uid } = data;
      const _url = `${url}/${uid}`;
      return axios.delete(_url);
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });
};

export const useMutate = (params: {
  url: string;
  uid?: string;
  fetcher?: string;
  headers?: AxiosRequestHeaders;
  invalidate?: string[];
  retryOn?: string;
  file?: boolean;
  useFetch?: boolean;
  withCredentials?: boolean;
}) => {
  let { url, uid, headers, invalidate, fetcher, file, retryOn, useFetch } = params;

  let toInvalidate = [url, ...(invalidate ? invalidate : [])];
  if (uid) {
    url = `${url}/${uid}`;
    toInvalidate.push(url);
  }
  return useMutation({
    retry(failureCount, error: any) {
      const message = error.message || error.errors || '';
      if (retryOn && typeof message === 'string' && message.toLowerCase().includes(retryOn.toLowerCase())) {
        return failureCount < 3;
      }
      return false;
    },
    mutationFn: (payload: any) => {
      if (headers === undefined) {
        headers = {} as AxiosRequestHeaders;
      }
      headers['Content-Type'] = 'application/json';
      if (payload instanceof FormData) {
        headers['Content-Type'] = 'multipart/form-data';
      }

      if (useFetch) {
        fetch(`${base_url}/${url}`, {
          method: uid ? 'PUT' : 'POST',
          headers: headers,
          body: payload
        }).then((response) => response.json());
      }

      if (!isEmpty(uid) || !isEmpty(payload.uid)) {
        const _uid = uid || payload.uid;
        if (!url.includes(_uid)) {
          url = `${url}/${_uid}`;
        }
        return axios.put(url, payload, { headers });
      }
      delete payload.uid;
      return axios.post(url, payload, { headers });
    },
    onSuccess: (resp) => {
      const { data } = resp;
      if (file) {
        saveAs(resp.data, resp.headers['content-disposition'].split('filename=')[1]);
      } else if (!uid && data.uid !== undefined) {
        if (fetcher) {
          toInvalidate.push(`${fetcher}/${data.uid}`);
        } else {
          toInvalidate.push(`${url}/${data.uid}`);
        }
      }

      if (toInvalidate.includes('all')) {
        queryClient.invalidateQueries();
      } else {
        toInvalidate.forEach((key) => {
          queryClient.invalidateQueries({ queryKey: [key] });
        });
      }
    }
  });
};
