import config from 'config';
import { AuthContext } from 'contexts/auth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/user';

// ==============================|| ADMIN GUARD ||============================== //

const AdminGuard = ({ children }: GuardProps) => {
  const { user, active, default_role } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!!user) {
      const { is_staff } = user;
      if (!is_staff || default_role !== 'system') {
        navigate(config.appPath, { replace: true });
      } else if (!active) {
        navigate(config.setupPath, { replace: true });
      }
    } else {
      navigate(config.loginPath, { replace: true });
    }
  }, [active, navigate, default_role, user]);

  return children;
};

export default AdminGuard;
