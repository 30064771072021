import config from 'config';
import { AuthContext } from 'contexts/auth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/user';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { user, active, default_role, company_id } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!!user) {
      if (user?.is_staff && default_role === 'system') {
        navigate(config.adminPath, { replace: true });
      } else if (!active || (!default_role && !company_id)) {
        navigate(config.setupPath, { replace: true });
      }
    } else {
      navigate(config.loginPath, { replace: true });
    }
  }, [active, navigate, user, default_role, company_id]);

  return children;
};

export default AuthGuard;
