import { createSlice } from '@reduxjs/toolkit';

export interface ModalProps {
  isReviewQuotationOpen: boolean;
  openCustomer: string;
  openPurchaseOrder: string;
  openSalesOrder: string;
  openDelivery: string;
  openInvoice: string;
  openQuote: string;
  openDeliveryNote: string;
  openAccount: string;
  openCashAccount: string;
  openExpenseAccount: string;
  openSupplierInvoice: string;
  openSupplier: string;
  openPaymentTerms: string;
  openProductCategory: string;
  openStaff: string;
  openProduct: string;
  openService: string;
  openRefSetup: string;
  openTax: string;
  openItem: string;
}

const initialState: ModalProps = {
  isReviewQuotationOpen: false,
  openCustomer: '',
  openPurchaseOrder: '',
  openSalesOrder: '',
  openDelivery: '',
  openInvoice: '',
  openQuote: '',
  openDeliveryNote: '',
  openAccount: '',
  openCashAccount: '',
  openExpenseAccount: '',
  openSupplierInvoice: '',
  openProductCategory: '',
  openSupplier: '',
  openPaymentTerms: '',
  openRefSetup: '',
  openStaff: '',
  openProduct: '',
  openService: '',
  openTax: '',
  openItem: ''
};

const quotation = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleReviewQuotationPopup(state, action) {
      state.isReviewQuotationOpen = action.payload;
    },

    setOpenCustomer(state, action) {
      state.openCustomer = action.payload;
    },

    setOpenPurchaseOrder(state, action) {
      state.openPurchaseOrder = action.payload;
    },

    setOpenProductCategory(state, action) {
      state.openProductCategory = action.payload;
    },

    setOpenSalesOrder(state, action) {
      state.openSalesOrder = action.payload;
    },

    setOpenDelivery(state, action) {
      state.openDelivery = action.payload;
    },

    setOpenInvoice(state, action) {
      state.openInvoice = action.payload;
    },

    setOpenQuote(state, action) {
      state.openQuote = action.payload;
    },

    setOpenDeliveryNote(state, action) {
      state.openDeliveryNote = action.payload;
    },

    setOpenAccount(state, action) {
      state.openAccount = action.payload;
    },

    setOpenCashAccount(state, action) {
      state.openCashAccount = action.payload;
    },

    setOpenExpenseAccount(state, action) {
      state.openExpenseAccount = action.payload;
    },

    setOpenSupplierInvoice(state, action) {
      state.openSupplierInvoice = action.payload;
    },

    setOpenSupplier(state, action) {
      state.openSupplier = action.payload;
    },

    setOpenStaff(state, action) {
      state.openStaff = action.payload;
    },

    setOpenItem(state, action) {
      state.openItem = action.payload;
    },

    setOpenTax(state, action) {
      state.openTax = action.payload;
    },

    setOpenRefSetup(state, action) {
      state.openRefSetup = action.payload;
    },
    setOpenProduct(state, action) {
      state.openProduct = action.payload;
    },
    setOpenService(state, action) {
      state.openService = action.payload;
    },

    setOpenPaymentTerms(state, action) {
      state.openPaymentTerms = action.payload;
    }
  }
});

export default quotation.reducer;

export const {
  setOpenAccount,
  setOpenCashAccount,
  setOpenCustomer,
  setOpenDelivery,
  setOpenDeliveryNote,
  setOpenExpenseAccount,
  setOpenInvoice,
  setOpenItem,
  setOpenPaymentTerms,
  setOpenProduct,
  setOpenProductCategory,
  setOpenPurchaseOrder,
  setOpenQuote,
  setOpenRefSetup,
  setOpenSalesOrder,
  setOpenService,
  setOpenStaff,
  setOpenSupplier,
  setOpenSupplierInvoice,
  setOpenTax,
  toggleReviewQuotationPopup
} = quotation.actions;
