import * as Sentry from '@sentry/browser';
import { queryOptions, useMutation } from '@tanstack/react-query';
import { queryClient } from 'api';
import { isEmpty } from 'lodash';
import axios from 'utils/axios';
import { DATE_FORMAT } from 'utils/constants';
import { formatDate } from 'utils/helpers';
import { list, useMutate } from './base';

export const getCurrentUser = () => {
  const url = '/me';
  return queryOptions({
    refetchInterval: Number(process.env.REACT_APP_ME_REFRESH_INTERVAL || 1) * 60 * 1000,
    staleTime: 0.9 * 60 * 1000,
    refetchIntervalInBackground: true,
    queryKey: [url],
    queryFn: async () => {
      const resp = await axios.get(url);
      const { preferences, roles: userRoles, ...user } = resp.data;
      const { company_id, default_role, menu } = preferences || {};

      const roles = userRoles?.filter((role: any) => (company_id ? role.company === company_id && role.is_active : role.is_active));
      const active = user?.is_active && (user?.is_staff || user?.is_superuser || (roles && roles.length > 0));
      if (isEmpty(user)) {
        Sentry.setUser(null);
      }

      return { user, active, company_id, default_role, menu: menu || 'top', roles };
    }
  });
};

export const getUserSessions = () => {
  const url = '/user-sessions';
  return list(url);
};

export const getUserPreferences = () => {
  const url = '/user-settings';
  return list(url);
};

export const useUpdatePreferences = () => {
  const url = '/user-settings';
  return useMutate({ url, invalidate: ['/me'] });
};

export const useSignIn = () => {
  const url = `/auth/login`;
  return useMutate({ url, retryOn: 'network' });
};

export const useSignOut = () => {
  const url = `/auth/logout`;
  return useMutate({ url });
};

export const useSignOutAll = () => {
  const url = `/auth/logoutall`;
  return useMutate({ url });
};

export const useCheckUser = () => {
  const url = `/check-user`;
  return useMutate({ url });
};

export const useSwitchRole = () => {
  const url = `/switch-role`;
  return useMutate({ url, invalidate: ['all'] });
};

export const useResetPassword = () => {
  const url = `/request-password-reset`;
  return useMutate({ url });
};

export const useUpdateProfile = () => {
  const url = '/me';
  return useMutation({
    mutationFn: (data: any) => {
      let headers: any = {};
      let formData = new FormData();
      if (data.avatar) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      Object.keys(data).forEach((key) => {
        if (key === 'dob') {
          formData.append(key, formatDate(data[key], DATE_FORMAT));
        } else {
          formData.append(key, data[key]);
        }
      });

      return axios.post(url, formData, { headers });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [url] });
    }
  });
};
