import config from 'config';
import { AuthContext } from 'contexts/auth';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/user';

// ==============================|| ROLE GUARD ||============================== //

const LandingGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const { user, active, default_role } = useContext(AuthContext);

  useEffect(() => {
    if (!!user) {
      if (user?.is_staff && default_role === 'system') {
        navigate(config.adminPath, { replace: true });
      } else if (active) {
        navigate(config.appPath, { replace: true });
      } else {
        navigate(config.setupPath, { replace: true });
      }
    } else {
      navigate(config.loginPath, { replace: true });
    }
  }, [active, navigate, user, default_role]);

  return children;
};

export default LandingGuard;
