import { NavItemType } from 'types/menu';
import payments from './payments';
import main from './main';
import purchases from './purchases';
import sales from './sales';
import settings from './settings';
import users from './users';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [main, purchases, sales, payments, users, settings]
};

export default menuItems;
