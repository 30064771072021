import _ from 'lodash';

export const formatResponse = (rData: any) => {
  let data = rData;
  let count;

  if (_.isArray(rData)) {
    data = rData;
    count = rData.length;
  } else if (Object.hasOwn(rData, 'results') && Object.hasOwn(rData, 'count')) {
    data = rData.results;
    count = rData.count;
  }
  return { data, count };
};

export const formatFormData = (data: any) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'pin_file' || key === 'files' || key.endsWith('_files')) {
      if (data[key]) {
        formData.append(key, data[key][0]);
      }
    } else if (!_.isEmpty(data[key])) {
      formData.append(key, data[key]);
    }
  });
  return formData;
};
